import React from "react"


const LoadingScreen = () => {
  return (
    <div className = " flex justify-center align-middle " >
   <img src ="/Y.png" class = "w-1/6 mt-40 align-middle animate-bounce hover:animate-spin sm: mt-70"/>
  
   </div>
  );
};

export default LoadingScreen;